import React from "react";
import './policy.css';

const PrivacyPolicy = () => {
    return (
        <div className="policy-body">
            PRIVACY POLICY<br/>
            INTERTWINE STUDIOS Privacy Policy<br/>
            This Privacy Policy describes how your personal information is collected, used, and
            shared when you visit or make a purchase from www.intertwinestudios.com .<br/>
            PERSONAL INFORMATION WE COLLECT<br/>
            When you visit the Site, we automatically collect certain information about your device,
            including information about your web browser, IP address, time zone, and some of the
            cookies that are installed on your device. Additionally, as you browse the Site, we collect
            information about the individual web pages that you view, what websites or
            search terms referred you to the Site, and information about how you interact with the
            Site. We refer to this automatically-collected information as “Device Information.”
            <br/>We collect Device Information using the following technologies:
            <br/>- “Cookies” are data files that are placed on your device or computer and often
            include an anonymous unique identifier. For more information about cookies, and how
            to disable cookies, visit http://www.allaboutcookies.org.
            <br/>- “Log files” track actions occurring on the Site, and collect data including your IP
            address, browser type, Internet service provider, referring/exit pages, and date/time
            stamps.
            <br/>- “Web beacons,” “tags,” and “pixels” are electronic files used to record information
            about how you browse the Site.
            <br/>
            When we talk about “Personal Information” in this Privacy Policy, we are talking both
            about Device Information and Order Information.<br/>
            HOW DO WE USE YOUR PERSONAL INFORMATION?<br/>
            We use the Order Information that we collect generally to fulfill any orders placed
            through the Site. Additionally, we
            use this Order Information to:
            Communicate with you;
            Screen our orders for potential risk or fraud; and
            When in line with the preferences you have shared with us, provide you with information
            or advertising relating to our products or services.
            We use the Device Information that we collect to help us screen for potential risk and
            fraud (in particular, your IP address), and more generally to improve and optimize our site (for example, by generating analytics about how our customers browse and
            interact with the Site, and to assess the success of our marketing and advertising
            campaigns).
            SHARING YOUR PERSONAL INFORMATION
            We share your Personal Information with third parties to help us use your Personal
            Information, as described above. For example, we use Google Analytics to help us
            understand how our customers use the Site--you can read more about how Google
            uses your Personal Information here: https://www.google.com/intl/en/policies/
            privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/
            dlpage/gaoptout.
            Finally, we may also share your Personal Information to comply with applicable laws
            and regulations, to respond to a subpoena, search warrant or other lawful request for
            information we receive, or to otherwise protect our rights.
            BEHAVIOURAL ADVERTISING
            As described above, we use your Personal Information to provide you with marketing communications we believe may be of interest to you.
            DO NOT TRACK
            Please note that we do not alter our Site’s data collection and use practices when we
            see a Do Not Track signal from your browser.
            DATA RETENTION
            When you place an order through the Site, we will maintain your Order Information for
            our records unless and until you ask us to delete this information.
            CHANGES
            We may update this privacy policy from time to time in order to reflect, for example,
            changes to our practices or for other operational, legal or regulatory reasons.
            CONTACT US
            For more information about our privacy practices, if you have questions, or if you would
            like to make a complaint, please contact us by e-mail at inquiries@intertwinestudios.com
        </div>
    )
}

export default PrivacyPolicy;