import React, { forwardRef, useImperativeHandle, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import './Popup.css'


const CustomAlert = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false)
    useImperativeHandle(ref, () => {
        return {
            openPopup: () => setOpen(true),
            closePopup: () => setOpen(false)
        };
    });

    return <AnimatePresence>
        {open && <motion.div
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
            className="popup-backdrop">
            <motion.div
                initial={{
                    scale: 0
                }}
                animate={{
                    scale: 1
                }}
                className="alert-wrapper">
                <p style={{color:"black"}}>{props.msg}</p>
                <button className="popup-close-button" onClick={() => setOpen(false)}>Close</button>
            </motion.div>
        </motion.div>}
    </AnimatePresence>
})

export default CustomAlert;