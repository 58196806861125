import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import CarouselContainer from './components/CarouselContainer/CarouselContainer';
import ServiceSection from './components/serviceSection/ServiceSection';
import NavbarBootstrap from './components/NavbarBootstrap/NavbarBootstrap';
import PackageSection from './components/PackagesSection/PackageSection';
import PortfolioSection from './components/Portfolio/PortfolioSection/PortfolioSection';
import PortfolioPage from './components/Portfolio/PortfolioPage/PortfolioPage';
import Footer from './components/Footer/Footer';
import { useState, useEffect } from 'react';
import { getServices, getLatestImages, getPackageInfo, getCarouselImages } from './utils/firebase.js'
import LoadingState from './components/LoadingState/LoadingState';
import TermsOfService from './components/TermsOfService/TermsOfService';
import ServicePolicy from './components/TermsOfService/ServicePolicy';
import PrivacyPolicy from './components/TermsOfService/PrivacyPolicy';
import Mission from './components/Mission/Mission';
import MissionPage from './components/Mission/MissionPage/MissionPage';

function App() {
  const [loaded, setLoading] = useState([]);
  const [services, setServices] = useState([]);
  const [carousel, setCarousel] = useState([]);
  const [latestPics, setPortfolio] = useState([]);
  const [packages, setPackages] = useState([]);
  const [ready, setReady] = useState(false);
  useEffect(() => {
    getServices().then(snapshot => {
      setLoading(loaded => [...loaded, true]);
      setServices(snapshot)
    });
    getLatestImages().then(snapshot => {
      setLoading(loaded => [...loaded, true]);
      setPortfolio(snapshot);
    });
    getPackageInfo().then(snapshot => {
      setLoading(loaded => [...loaded, true]);
      setPackages(snapshot);
    });
    getCarouselImages().then(snapshot => {
      setLoading(loaded => [...loaded, true]);
      setCarousel(snapshot);
    });
  }, [setLoading]);
  setTimeout(()=>{
    setReady(true)
  }, 3000)
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          {loaded.length === 4 && ready
            ? <div>
              <NavbarBootstrap />
              <CarouselContainer images={carousel} />
              <Mission/>
              <ServiceSection services={services} />
              <PackageSection packages={packages} />
              <PortfolioSection images={latestPics} />
              <Footer />
            </div>
            : <LoadingState />
          }
        </Route>
        <Route path="/mission">
          <MissionPage />
        </Route>
        <Route path="/portfolio">
          <PortfolioPage />
          <Footer />
        </Route>
        <Route path="/terms-of-service">
          <TermsOfService />
        </Route>
        <Route path="/service-policy">
          <ServicePolicy />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
