import React, { useRef } from "react";

import PackageOptions from "../PackageOptions/PackageOptions";
import "../PackageSection.css";


const PackageWrapper = (props) => {
    const idealHeight = useRef(32);
    const lastScrollHeight = useRef(30);
    const textAreaRef = useRef(null);

    if (textAreaRef.current !== null && textAreaRef.current !== undefined) {

        textAreaRef.current.style.height = '0px'; // This creates an inline style

        let scrollHeight = textAreaRef.current.scrollHeight;

        const style = window.getComputedStyle(textAreaRef.current);

        textAreaRef.current.removeAttribute(style); // The inline style must be removed

        let delta = scrollHeight - lastScrollHeight.current;
        lastScrollHeight.current = scrollHeight;

        idealHeight.current = idealHeight.current + delta;
    }
    return (
        <div className="packageOptions-content-parent" ref={textAreaRef} style={props.isHidden ? { height:'0px' } : { height: idealHeight.current + 'px' }}>
            <PackageOptions title={props.title} isHidden={props.isHidden} data={props.data} />
        </div>
    )
}

export default PackageWrapper;