import React from "react";
import { Link } from "react-router-dom";

import './PortfolioSection.css';

const PortfolioSection = (props) => {
    return (
        <div className="wrapper" id="portfolio">
            <div className="portfolio-preview">
                <div className="portfolio-pics one" style={{ backgroundImage: `url(${props.images[0]})` }} />
                <div className="portfolio-pics two" style={{ backgroundImage: `url(${props.images[1]})` }} />
                <div className="portfolio-pics three" style={{ backgroundImage: `url(${props.images[2]})` }} />
                <div className="portfolio-pics four" style={{ backgroundImage: `url(${props.images[3]})` }} />
                <div className="portfolio-pics five" style={{ backgroundImage: `url(${props.images[4]})` }} />
            </div>
            <div className="portfolio-button-container">
                <Link to="/portfolio" target="_blank" className="neumobutton">Check our Portfolio</Link>
            </div>
            <div className="description">
                <h1>ABOUT US</h1>
                <hr className="solid"></hr>
                <p id="aboutus">
                    Intertwine studios is a multifaceted visualization company. We specialize in 3D visualization and presentation covering the diverse layers of modeling and rendering.
                    We focus on voicing the client's design vision through the creative output that intertwines photorealism, composition, beautiful lighting, mood and much more to provide a high-end piece of art.
                </p>
                <hr className="solid"></hr>
            </div>
        </div>
    )
}

export default PortfolioSection;