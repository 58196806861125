import React from "react";

import { Calendar } from "react-multi-date-picker";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import "react-multi-date-picker/styles/colors/teal.css"
import Popup from "../../Popup/Popup";
import CustomAlert from "../../Popup/CustomAlert";
import "./PackageOptions.css";


class PackageOptions extends React.Component {

    constructor(props) {
        super(props);
        this.handleEvent = this.handleEvent.bind(this);
        this.changeTba = this.changeTba.bind(this);
        this.changeStyle = this.changeStyle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selectStyle = this.selectStyle.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.popupRef = React.createRef();
        this.alertRef = React.createRef();
        this.state = {
            width: window.innerWidth,
            selectedOptions: [],
            style: "",
            msg: "",
            tba: 0,
            dueDate: "",
            data: {}
        };
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    handleChange(item, checked) {
        checked
            ? this.setState({ selectedOptions: [...this.state.selectedOptions, item] })
            : this.setState({
                selectedOptions: this.state.selectedOptions.filter((c) => c !== item)
            });
    }

    selectStyle(style) {
        this.setState({
            style: style
        });
    }

    handleEvent(event) {
        event.preventDefault();
        if (this.state.selectedOptions.length === 0) {
            this.setState({ msg: 'Please select at least one option' });
            return this.alertRef.current.openPopup();
        }
        if (this.state.style === "") {
            this.setState({ msg: 'Please select a design style' });
            return this.alertRef.current.openPopup();
        }
        if (this.state.dueDate === "") {
            this.setState({ msg: 'Please select a date' });
            return this.alertRef.current.openPopup();
        }
        this.setState({
            data: {
                "tba": this.state.tba,
                "style": this.state.style,
                "due": this.state.dueDate,
                "options": this.state.selectedOptions,
                "title": this.props.title
            }
        })
        this.popupRef.current.openPopup();
    }

    MyPlugin() {
        return <p className="calender-header-text" style={{ textAlign: "left", paddingLeft: "10px", marginBottom: "0px" }}>
            Please choose your due date :
        </p>;
    }

    changeDate(value) {
        this.setState({ dueDate: value });
    }
    changeTba(value) {
        this.setState({ tba: value.target.value })
    }
    changeStyle(value) {
        this.setState({ style: value.target.value });
    }



    render() {
        const today = new Date().getDate();
        return (
            <div className={`packageOptions-container ${this.props.isHidden && "hidden"}`}>
                <form onSubmit={this.handleEvent}>
                    <div className="packageOptions-header">

                        <h3>{this.props.title}</h3>
                        <div className="packageOptions-info-row">
                            <div className="packageOptions-info-innerrow">

                                <div className="packageOptions-divider"></div>
                                <p className="p">Please enter project criteria :</p>
                                <div className="packageOptions-tba">
                                    <p className="p">TBA (Total Built Area) :</p>
                                    <input className="tba-input" placeholder="type here" type="number" onChange={this.changeTba} required></input>
                                </div>
                                <div className="packageOptions-styles">
                                    <p className="p">Design Style :</p>
                                    <div className="design-styles">
                                        <button type="button" className={`designStyle-button ${this.state.style === "Classical" ? "buttonActive" : ""}`} onClick={() => { this.selectStyle("Classical") }}>Classical</button>
                                        <button type="button" className={`designStyle-button ${this.state.style === "Contemporary" ? "buttonActive" : ""}`} onClick={() => { this.selectStyle("Contemporary") }}>Contemporary</button>
                                        <button type="button" className={`designStyle-button ${this.state.style === "Commercial" ? "buttonActive" : ""}`} onClick={() => { this.selectStyle("Commercial") }}>Commercial</button>
                                        <button type="button" className={`designStyle-button ${this.state.style === "Parametric" ? "buttonActive" : ""}`} onClick={() => { this.selectStyle("Parametric") }}>Parametric</button>
                                        <textarea className="design-style-textarea" placeholder="Other..." onChange={this.changeStyle}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="packageOptions-calendar">
                                <Calendar
                                    required={true}
                                    className="bg-dark teal"
                                    disableYearPicker={true}
                                    weekStartDayIndex={1}
                                    numberOfMonths={this.state.width > 580 ? 2 : 1}
                                    minDate={new Date().setDate(today)}
                                    mapDays={({ date }) => {
                                        let isWeekend = [0, 6].includes(date.weekDay.index)
                                        if (isWeekend) return {
                                            disabled: true,
                                            style: { color: "#f00" },
                                        }
                                    }}
                                    onChange={this.changeDate}
                                    plugins={[<this.MyPlugin position="top" />]}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="packageOptions-divider" style={{ marginLeft: "40px", marginTop: "40px" }}></div>


                    <div className="packageOptions-picker">
                        <div className="packageOptions-mobile" style={{ flex: "1 1 30%" }}>
                            <h3 style={{ paddingBottom: "20px" }}>3D Modeling</h3>
                            {
                                this.props.data.options["3d_Modeling"].map((item, key) => {
                                    return item === 'Modeling Software' ? (<div>
                                        {item}
                                        <label key='rhino' className="checkbox-container">
                                            <input key='rhino_key' type="checkbox" id={`checkbox_rhino`} name="3D_Modeling" value='Rhino 3D' className="checkbox-style" onChange={(e) => this.handleChange(item, e.target.checked)} />
                                            <span className="checkbox-span"></span>
                                            Rhino 3D
                                        </label>
                                        <label key='3ds' className="checkbox-container">
                                            <input key='3ds_key' type="checkbox" id='checkbox_3ds' name="3D_Modeling" value='3DS Max' className="checkbox-style" onChange={(e) => this.handleChange(item, e.target.checked)} />
                                            <span className="checkbox-span"></span>
                                            3DS Max
                                        </label>
                                        <label key='sketchup' className="checkbox-container">
                                            <input key='sketchup_key' type="checkbox" id='checkbox_sketchup' name="3D_Modeling" value='sketchup' className="checkbox-style" onChange={(e) => this.handleChange(item, e.target.checked)} />
                                            <span className="checkbox-span"></span>
                                            Sketchup
                                        </label>
                                    </div>) : (
                                        <label key={key + 'label'} className="checkbox-container">
                                            <input key={key} type="checkbox" id={`checkbox_${key.toString}`} name="3D_Modeling" value={item} className="checkbox-style" onChange={(e) => this.handleChange(item, e.target.checked)} />
                                            <span className="checkbox-span"></span>
                                            {item}
                                        </label>
                                    )
                                })
                            }
                        </div>
                        <div className="packageOptions-mobile" style={{ flex: "1 1 30%" }}>
                            <h3 style={{ paddingBottom: "20px" }}>Rendering</h3>
                            {
                                this.props.data.options["Rendering"].map((item, key) => {
                                    return (
                                        <label key={key + 'label'} className="checkbox-container">
                                            <input key={key} type="checkbox" id={`checkbox_${key.toString}`} name="Rendering" value={item} className="checkbox-style" onChange={(e) => this.handleChange(item, e.target.checked)} />
                                            <span className="checkbox-span"></span>
                                            {item}
                                        </label>
                                    )
                                })
                            }
                        </div>
                        <div className="packageOptions-mobile" style={{ flex: "1 1 30%" }}>
                            <h3 style={{ paddingBottom: "20px" }}>Extras</h3>
                            {
                                this.props.data.options["Extras"].map((item, key) => {
                                    return (
                                        <label key={key + 'label'} className="checkbox-container">
                                            <input key={key} type="checkbox" id={`checkbox_${key.toString}`} name="Extras" value={item} className="checkbox-style" onChange={(e) => this.handleChange(item, e.target.checked)} />
                                            <span className="checkbox-span"></span>
                                            {item}
                                        </label>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <button className="neumobutton" style={{ marginRight: "40px", marginTop: "20px", marginBottom: "20px" }} type="submit"
                        >PLACE ORDER</button>
                    </div>
                </form>
                <Popup data={this.state.data} ref={this.popupRef}></Popup>
                <CustomAlert msg={this.state.msg} ref={this.alertRef} />
            </div>
        )
    }
}

export default PackageOptions;