import React, { forwardRef, useImperativeHandle, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { collection, addDoc, getDoc, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from '../../utils/firebase';
import { Link } from "react-router-dom";

import './Popup.css'
import LoadingSpinner from "./LoadingSpinner";


const Popup = forwardRef((props, ref) => {
    const [loading, setLoading] = useState(false);
    const [success, setStatus] = useState(false);
    const [newsletter, toggleNewsletter] = useState(true)
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [about, setAbout] = useState("");
    const [tosWarning, toggleWarning] = useState(false);
    const [tos, toggleTos] = useState(false)
    const [open, setOpen] = useState(false)
    const [reference, setReference] = useState("")
    useImperativeHandle(ref, () => {
        return {
            openPopup: () => setOpen(true),
            closePopup: () => setOpen(false)
        };
    });


    const postOrder = async (data) => {
        try {
            setLoading(true);
            await addDoc(collection(db, "orders"), data);
            if (data.newsletter) {
                const emails = await getDoc(doc(db, "newsletter", "users"));
                console.log(emails.data()['users']);
                if (emails.data()['users'].indexOf(data.email) === -1) {
                    await updateDoc(doc(db, "newsletter", "users"), {
                        users: arrayUnion(data.email)
                    });
                }
            }
            setLoading(false);
            setStatus(true);
        } catch (e) {
            console.error(e)
        }
    }

    const handleEvent = (event) => {
        event.preventDefault();
        if (!tos) {
            return toggleWarning(true);
        }
        const data = {
            package: props.data['title'],
            tba: props.data["tba"],
            due: `${props.data["due"].day}/${props.data["due"].month}/${props.data["due"].year}`,
            options: props.data["options"],
            style: props.data["style"],
            name: name,
            email: email,
            about: about,
            newsletter: newsletter,
            reference: reference
        }
        postOrder(data);
    }

    return <AnimatePresence>
        {open && <motion.div
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
            className="popup-backdrop">
            <motion.div
                initial={{
                    scale: 0
                }}
                animate={{
                    scale: 1
                }}
                className="popup-wrapper">
                {loading ? <LoadingSpinner />
                    : success ?
                        <div className="spinner-container">
                            <p className="success-message">Thank you for reaching us. Your inquiry has been received and we will contact you shortly.</p>
                            <button className="popup-close-button" type="button" onClick={() => setOpen(false)} >Close</button>
                        </div>
                        : <form onSubmit={handleEvent}>
                            <input placeholder="Full name" name="name" className="popup-email" onChange={(e) => setName(e.target.value)} required />
                            <input placeholder="Email address" name="email" className="popup-email" onChange={(e) => setEmail(e.target.value)} required />
                            <textarea className="popup-textarea" rows={3} placeholder="Describe what your project is about" onChange={(e) => setAbout(e.target.value)} required />
                            <select onChange={(e) => { setReference(e.target.value) }} style={{ height: "40px" }} className="popup-email" required>
                                <option value="" disabled selected>How did you hear about us</option>
                                <option value="Instagram">Instagram</option>
                                <option value="Youtube">Youtube</option>
                                <option value="LinkedIn">LinkedIn</option>
                                <option value="Google">Google search</option>
                                <option value="Other">Other</option>
                            </select>
                            <label key='newsletter_key' style={{ color: '#000' }} className="checkbox-container">
                                <input key='newsletter_input_key' type="checkbox" id='newsletter' name="newsletter" className="checkbox-style" checked={newsletter} onChange={() => toggleNewsletter(!newsletter)} />
                                <span className="checkbox-span" htmlFor='newsletter'></span>
                                Subscribe to our newsletter
                            </label>
                            <label key='tos_key' style={{ color: '#000', marginBottom: '0px' }} className="checkbox-container" >
                                <input key='tos_input_key' type="checkbox" id='tos' name="tos" className="checkbox-style" checked={tos} onChange={() => toggleTos(!tos)} />
                                <span className="checkbox-span" htmlFor='tos'></span>
                                I Agree to <Link to="/terms-of-service" target="_blank">Terms of service</Link>, <Link to="/privacy-policy" target="_blank">Privacy policy</Link> and <Link to="/service-policy" target="_blank">Service policy</Link>
                            </label>
                            {tosWarning && <p className="tos-alert">Please agree to Terms of Service</p>}
                            <div className="buttons-div">
                                <button className="popup-close-button" type="button" onClick={() => setOpen(false)} >Close</button>
                                <button className="popup-submit-button" type="submit" >Ask for Quotation</button>
                            </div>
                        </form>}
            </motion.div>
        </motion.div>}
    </AnimatePresence>
})

export default Popup;