import { React, useState, useRef } from "react";
import { BsInstagram, BsBehance, BsFacebook, BsLinkedin, BsEnvelopeOpenFill } from "react-icons/bs";
import CustomAlert from '../Popup/CustomAlert';

import "./Footer.css";

const Footer = () => {
    const ref = useRef();
    const [msg, setMsg] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [body, setBody] = useState('');
    const [reference, setReference] = useState("")
    const handleEvent = (event) => {
        event.preventDefault();
        fetch('https://us-central1-intertwine-studios.cloudfunctions.net/sendEmail', {
            method: 'POST',
            body: JSON.stringify({
                name: name,
                bodyText: body,
                email: email,
                reference: reference
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((e) => {
            setMsg('Your email has been received. We will contact you shortly.')
            setBody("");
            setEmail("");
            setName("");
            ref.current.openPopup();
        }).catch((err) => {
            console.log(err.message);
            setMsg('Something went wrong. Please try again.')
            ref.current.openPopup();

        });
    }

    return (
        <div id="contact" className="footer">
            <div className="footer-wrapper">
                <div className="footer-left">
                    <a className="mailto" href="mailto:inquiries@intertwinestudios.com">
                        <BsEnvelopeOpenFill />
                        <p>inquiries@intertwinestudios.com</p>
                    </a>
                    <h5>Follow us</h5>
                    <ul className="social-media-list">
                        <li className="social-media-item"><a className="social-media-anchors" href="https://www.instagram.com/intertwine_studios/"> <BsInstagram /> </a></li>
                        <li className="social-media-item"><a className="social-media-anchors" href="https://www.facebook.com/intertwine.studios/"> <BsFacebook /> </a></li>
                        {/* <li className="social-media-item"><a className="social-media-anchors" href="https://twitter.com/Intertwinestu"> <BsTwitter /> </a></li> */}
                        <li className="social-media-item"><a className="social-media-anchors" href="https://www.linkedin.com/company/intertwine-studios/"> <BsLinkedin /> </a></li>
                        {/* <li className="social-media-item"><a className="social-media-anchors" href="https://www.tiktok.com/@intertwine.studios">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tiktok" viewBox="0 0 16 16">
                                <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
                            </svg> </a></li> */}
                        <li className="social-media-item"><a className="social-media-anchors" href="https://www.behance.net/intertwinestudios/"> <BsBehance /> </a></li>
                    </ul>
                </div>
                <div className="footer-right">
                    <h5>Contact Us</h5>
                    <form onSubmit={handleEvent}>
                        <label htmlFor="contact_name">Full Name:</label>
                        <input className="footer-tba-input" value={name} id="contact_name" name="contact_name" onChange={(e) => setName(e.target.value)} required />
                        <label htmlFor="contact_email">Email:</label>
                        <input className="footer-tba-input" value={email} id="contact_email" name="contact_email" onChange={(e) => setEmail(e.target.value)} required />
                        <label htmlFor="contact_message">How can we help:</label>
                        <textarea className="footer-tba-input" value={body} id="contact_message" name="contact_message" rows={4} onChange={(e) => setBody(e.target.value)} required />
                        <select onChange={(e) => { setReference(e.target.value) }}  className="footer-select" required>
                            <option value="" disabled selected>How did you hear about us</option>
                            <option value="Instagram">Instagram</option>
                            <option value="Youtube">Youtube</option>
                            <option value="LinkedIn">LinkedIn</option>
                            <option value="Google">Google search</option>
                            <option value="Other">Other</option>
                        </select>
                        <button className="package-button" type="Submit">Submit</button>
                    </form>
                </div>
            </div>
            <CustomAlert msg={msg} ref={ref} />
        </div>
    )
}

export default Footer;