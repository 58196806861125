export const anchors = {
    "title": "Anchors",
    "description":
        "Turn your drawings into highly-detailed scenes in first-class, professional and photorealistic visualizations with intertwine studios.\nThis high-end service supplies you with a leisurely and thorough experience that brings about the greatest ideas to life in upmost status.",
    options: {
        "3d_Modeling": [
            "Detailed Modeling - Exterior",
            "Detailed Modeling - Interior",
            "Environment and Context Modeling",
            "Modeling Software"
        ],
        "Rendering": [
            "Visual Effects",
            "Context and Surrounding",
        ],
        "Extras": [
            "Photoshop Post-Production",
            "Lightroom Post-Production",
            "Animation Video",
        ]
    }
}