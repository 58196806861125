import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { limit, collection, getDocs, query } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyDqe1F8d03CUjGiQ6AinziyM-1mc7apXkA",
  authDomain: "intertwine-studios.firebaseapp.com",
  projectId: "intertwine-studios",
  storageBucket: "intertwine-studios.appspot.com",
  messagingSenderId: "583156340655",
  appId: "1:583156340655:web:fbbb11016ba6da164b815a",
  measurementId: "G-XJ9864KV5Q"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app);

export const getLatestImages = async () => {
  let images = [];
  const q = query(collection(db, "portfolio"), limit(5));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
      images.push(doc.data()['path']);
  });
  return images;
}

export const getServices = async () => {
  let images = [];
  const querySnapshot = await getDocs(collection(db, "services"));
  querySnapshot.forEach((doc) => {
      images.push(doc);
  });
  return images;
}

export const getPackageInfo = async () => {
  let images = new Map();
  const querySnapshot = await getDocs(collection(db, "packages"));
  querySnapshot.forEach((doc) => {
      images[doc.id]= doc.data();
  });
  return images;
}

export const getCarouselImages = async () => {
  let images = [];
  const querySnapshot = await getDocs(collection(db, "carousel"));
  querySnapshot.forEach((doc) => {
      images.push(doc.data());
  });
  return images;
}
export default firebaseConfig;