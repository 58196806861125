import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import logo from "../../images/logo.png";
import "./NavbarBootstrap.css";

const NavbarBootstrap = () => {
    return (
        <Navbar  bg="light" variant="dark" expand="lg" fixed="top" style={{ padding: "0px", margin: "0px" }} >
            <Container style={{ maxWidth: "100%" }}>
                <div className="banner-container">
                    <img
                        alt=""
                        src={logo}
                        className="d-inline-block align-top website-logo"
                    />
                    
                    <div className="title-container">
                        <p className="title">Intertwine</p>
                        <p className="subtitle">Studios</p>
                    </div>
                </div>
                
                <Navbar.Toggle aria-controls="basic-navbar-nav" style={{padding:"0px", borderWidth:"0px", color:"transparent"}}/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="justify-content-end" style={{ width: "100%" }} >
                        <div className="animation-container">
                            <Nav.Link href="#home" id="home-nav-button" className="nav-boot-buttons">Home</Nav.Link>
                            <Nav.Link href="#mission" id="home-nav-button" className="nav-boot-buttons">Mission</Nav.Link>
                            <Nav.Link href="#packages" className="nav-boot-buttons">Packages</Nav.Link>
                            <Nav.Link href="#portfolio" className="nav-boot-buttons">Portfolio</Nav.Link>
                            <Nav.Link href="#aboutus" className="nav-boot-buttons">About Us</Nav.Link>
                            <Nav.Link href="#contact" className="nav-boot-buttons">Contact</Nav.Link>
                        </div>
                        
                    </Nav>
                </Navbar.Collapse>
                <script src="./ActiveNavItem.js"></script>
            </Container>
        </Navbar>
    );
}

export default NavbarBootstrap;