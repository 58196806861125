export const bends = {
    "title": "Bends",
    "description": "Convey your design concepts through eye-catching visuals in a fresh and modern manner.\nThis custom service supplies you with unhurried and inexpensive end results. This package is your best fit if you have an open-deadline project with a certain budget in mind.",
    options: {
        "3d_Modeling": [
            "Basic Modeling - Exterior",
            "Basic Modeling - Interior",
            "Basic Environment and Context Modeling",
            "Modeling Software"
        ],
        "Rendering": [
            "Basic Visual Effects",
            "Basic Context and Surrounding",
        ],
        "Extras": [
            "Photoshop Post-Production",
            "Lightroom Post-Production",
        ]
    }
}