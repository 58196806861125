import React from "react";

import logo from "../../images/colored_logo.png";

import './LoadingState.css';

const LoadingState = () => {
    return (
        <div className="loading-state-bg">
            <div className="banner-container loading-center">
                <img
                    alt=""
                    src={logo}
                    className="img-loading rotate"
                />
                <div className="title-container">
                    <p className="title-loading">Intertwine</p>
                    <p className="subtitle-loading">Studios</p>
                </div>
            </div>
        </div>
    )
}

export default LoadingState;