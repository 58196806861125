import React from "react";

import "./CardStyle.css"

const CardStyle = (props) => {
    return (
        <div className="cardstyle-container">
            <div className="mobile-drop-shadow">
                <section className={props.position === "right" ? "cardstyle-skewbox-right" : "cardstyle-skewbox-left"}>
                    {
                        props.position === "right"
                            ? <div className="cardstyle-content">
                                <h1 className="cardstyle-h1">{props.title}</h1>
                                <p className="services-p">
                                    {props.description}
                                </p>
                            </div>
                            : <div className={`cardstyle-imagebox-left ${props.angle}`} style={{ backgroundImage: `url(${props.backgroundImage})` }}></div>
                    }
                    {
                        props.position === "right"
                            ? <div className={`cardstyle-imagebox ${props.angle}`} style={{ backgroundImage: `url(${props.backgroundImage})` }}></div>
                            : <div className="cardstyle-content">
                                <h1 className="cardstyle-h1">{props.title}</h1>
                                <p className="services-p">
                                    {props.description}
                                </p>
                            </div>
                    }
                </section>
            </div>
        </div>

    )
}

export default CardStyle;