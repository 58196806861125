import React from "react";

import './policy.css';

const ServicePolicy = () => {
    return (
        <div className="policy-body">
            Intertwine Studios Policies:
            <br /><br />
            Please read carefully the following rules and terms and check the box below in order to proceed:
            <ol>
                <br />
                <li>All project requirements must be provided, along with the clients’ needs and requests that must be clearly stated before the initiation phase, otherwise extra charges are generated. Any project presented without its full documents would be placed on hold. </li>
                <br />
                <li>If there are any references to be followed, the client has to make sure to mention them from the beginning.</li>
                <br />
                <li>During the closing phase, the client has the right to check and come back to our employees for minor edits if needed.</li>
                <br />
                <li>In case the client forgot to mention a requirement after the project was finalized, changes will lead to extra charges.</li>
            </ol>
            <br />
            Payment Terms:
            <br />
            <ol>
                <li>Payments under $1000 must be fully paid after the project is discussed at the end of the initiation phase.</li>
                <br />
                <li>Payments above $1000 would be divided between two major phases:
                    <ol>
                        <br />
                        <li>Half of the payment would be paid after discussing the project at the end of the initiation phase.</li>
                        <br />
                        <li>The second half would be paid after the final submission of the project during the closing phase.</li>
                    </ol>
                </li>
                <br />
                <li>Failure to make payments at the agreed due dates will lead to additional late fees.</li>
            </ol>
            <br />
            Intertwine Studios is not responsible for any time lag affecting the project delivery which may occur due to the lack or delay in supplying the necessary files at the planning phase or in case of any communication failure on the part of the client. <br /><br />
            Intertwine studios is continuously developing its service system, as it strictly adopts the concept of punctuality to ensure that all projects are timely delivered, guaranteeing its clients’ satisfaction.
        </div>
    )
}

export default ServicePolicy;