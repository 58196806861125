import React from "react";
import { Link } from "react-router-dom";

import './Mission.css';

const Mission = () => {
    return (
        <div id="mission" className="mission-back">
            <div className="mission-content">
                <h3 className="mission-desc mission-header"><b>Are you looking for talented  storytelling visualizers?</b></h3><br />
                <h5 className="mission-desc">Whether you are an architect, real estate broker, interior designer, product designer or even own a furniture brand we have something for you.</h5>
                <br /><h5 className="mission-desc">Businesses struggle to tell a story and deliver a vision through their renders, therefore they fail to increase traffic and sales and as a result investing in photorealistic renders doesn't seem worth it anymore.</h5>
                <br /><h5 className="mission-desc">At Intertwine Studios we focus on voicing the client's vision, and highlighting the project's unique aspects through a well thought of rendered image, or a story driven animated video with a marketing focus which targets the customers' emotions.</h5>
                <br /><h5 className="mission-desc" style={{marginBottom:"0px"}}>We are confident we can help you achieve your milestones, and always happy to listen to your requests. Feel free to leave us an inquiry, otherwise you can check the detailed page of our mission.</h5>
                <div className="button-spacer">
                    <Link to="/mission" target="_blank"><button className="neumobutton">Explore Our Mission</button></Link>
                </div>
            </div>

        </div>
    );
}

export default Mission;