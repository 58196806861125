import React, { useState, useRef } from "react";
import SinglePackage from "./SinglePackage/SinglePackage";

import "./PackageSection.css";
import PackageWrapper from "./PackageWrapper/PackageWrapper";

import { knots } from "./PackageData/data_knots.js";
import { bends } from "./PackageData/data_bends.js";
import { hitches } from "./PackageData/data_hitches.js";
import { anchors } from "./PackageData/data_anchors.js";


const PackageSection = (props) => {
    const scrollRef = useRef();
    const [isPackageOpen, setPackageOpen] = useState([false, false, false, false]);
    function checkOpened(isPackageOpen) {
        for (let i = 0; i < isPackageOpen.length; i++) {
            if (isPackageOpen[i]) {
                return true;
            }
        }
        return false;
    }

    return (
        <div id="packages" className="package-section-container">
            <div className="continous-background" >
                <h1>PACKAGES</h1>
                <hr className="solid"></hr>
                <p className="">Our Rendering services communicate architectural design concepts in lifelike situations.
                    We deliver fast & affordable renderings with high-fidelity visualization & immersive walkthroughs at any stage of a project.
                </p>
                <div className="continous-background-extension"></div>
            </div>
            <div className="packages-row">
                <SinglePackage title={knots["title"]} description={knots["description"]} img={props.packages.KNOTS} isActive={isPackageOpen[0]} somethingActive={checkOpened(isPackageOpen)} stateMethod={() => { setPackageOpen([!isPackageOpen[0], false, false, false]); !isPackageOpen[0] && scrollRef.current?.scrollIntoView(); }} />
                <SinglePackage title={bends["title"]} description={bends["description"]} img={props.packages.BENDS} isActive={isPackageOpen[1]} somethingActive={checkOpened(isPackageOpen)} stateMethod={() => { setPackageOpen([false, !isPackageOpen[1], false, false]); !isPackageOpen[1] && scrollRef.current?.scrollIntoView(); }} />
                <SinglePackage title={hitches["title"]} description={hitches["description"]} img={props.packages.HITCHES} isActive={isPackageOpen[2]} somethingActive={checkOpened(isPackageOpen)} stateMethod={() => { setPackageOpen([false, false, !isPackageOpen[2], false]); !isPackageOpen[2] && scrollRef.current?.scrollIntoView(); }} />
                <SinglePackage title={anchors["title"]} description={anchors["description"]} img={props.packages.ANCHORS} isActive={isPackageOpen[3]} somethingActive={checkOpened(isPackageOpen)} stateMethod={() => { setPackageOpen([false, false, false, !isPackageOpen[3]]); !isPackageOpen[3] && scrollRef.current?.scrollIntoView(); }} />
            </div>
            <div className="package-details" id="pack-options" ref={scrollRef}>
                <PackageWrapper title={'Knots'} isHidden={!isPackageOpen[0]} data={knots} />
                <PackageWrapper title={'Bends'} isHidden={!isPackageOpen[1]} data={bends} />
                <PackageWrapper title={'Hitches'} isHidden={!isPackageOpen[2]} data={hitches} />
                <PackageWrapper title={'Anchors'} isHidden={!isPackageOpen[3]} data={anchors} />
            </div>
        </div>
    )
}

export default PackageSection;
