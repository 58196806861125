import React from 'react';
import CardStyle from './CardStyle/CardStyle';

import "./ServiceSection.css";

const ServiceSection = (props) => {
    const styles = ["medium-angle", "small-angle", "big-angle", "big-angle", "small-angle"];
    return (
        <div className="background">
            {props.services.map((doc, index) => (
                <CardStyle
                    backgroundImage={doc.data()['path']}
                    position={index % 2 === 0 ? "left" : "right"}
                    angle={styles[index]}
                    title={doc.id}
                    description={doc.data()['desc']} />
            ))}
            <div style={{ height: "50px" }}></div>
        </div>
    )
}

export default ServiceSection;