import React from "react";

import "./SinglePackage.css";

const SinglePackage = (props) => {
    return (
        <div className="package-container">
            <img className="package-image" alt={props.img['alt']} src={props.img['path']} />
            <div className="description-outer-container">
                <div className="description-container">
                    <h5 className={`
                            ${props.isActive && " buttonActive"} ${props.somethingActive && " unselected"}`}>{props.title}</h5>
                    <p className={`package-text
                            ${props.isActive && " buttonActive"} ${props.somethingActive && " unselected"}`
                        }>{props.description}</p>
                </div>
                <button  onClick={props.stateMethod} className={`neumobutton ${props.isActive?"active":""} ${props.somethingActive && " button-unselected"}`}>
                    {!props.isActive ? "Expand":"Collapse"}
                </button>
            </div>
        </div>
    )
}

export default SinglePackage;