import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer"


import logo from "../../../images/whitelogo.png";
import './MissionPage.css';

const Mission = () => {
    return (
        <div>
            <div className="mission-body">
                <div className="mission-blur">
                    <div className="portfolio-header">
                        <div className="portfolio-logo">
                            <Link to="/" target="_blank" className="link-portfolio">
                                <div className="banner-container">
                                    <img
                                        alt=""
                                        src={logo}
                                        className="d-inline-block align-top website-logo"
                                    />
                                    <div className="title-container">
                                        <p className="title">Intertwine</p>
                                        <p className="subtitle">Studios</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div class="vl"></div>
                        <h1 className="portfolio-title">OUR MISSION</h1>
                    </div>
                    <div className="mission-section">
                        <div className="mission-title">
                            <h1><b>ARE YOU AN ARCHITECT?</b></h1>
                            <h5>There's nothing more convincing than a <b>well-told viusal story.</b><br />Get <b>your</b> design approved and <b>build your portfolio</b> with us!</h5>
                        </div>
                        <div>
                            <p><b>
                                During each phase of the design process, architects draft unique design proposals and tend to visualize them in the back of their minds, but they often lack the time or expertise to translate that vision into a well-thought of realistic representation for client review.
                                <br /><br />
                                A quick and easy visualization might not be able to express a specific mood, highlight a certain experience, or even resonate with the client. Therefore, the latter might feel resentful toward the end product, making all design efforts go without appreciation.
                                <br /><br />
                                Our team will take the time to understand your vision, goals and objectives to create vivid and lifelike imagery or animation videos by focusing on all aspects of 3d rendering, from lighting to materials and setting.
                            </b>
                            </p>
                        </div>
                    </div>
                    <div className="mission-section">
                        <div className="mission-title">
                            <h1><b>DO YOU OWN A REAL ESTATE DEVELOPMENT FIRM?</b></h1>
                            <h5>A picture is worth a thousand words, <b>but</b> what about a visual marketing message that can make <b>your</b> clients <b>feel somthing?</b></h5>
                        </div>
                        <div>
                            <p><b>
                                Most of the real estate brokerage firms stick to traditional marketing strategies which consist of showing empty spaces, highlighting the number of rooms, total area of the property, and the outside view in some cases. Some firms go the extra length and create a visualization of the available space filled with furniture and home appliances to give a realistic look and a sense of life to the place. This strategy will make the end result look like any other normal listing on competitor websites and therefore potential buyers won’t be intrigued to visit the platform and learn more about the properties.
                                <br /><br />
                                What businesses fail to understand is that by doing such a strategy, the marketing is focused toward the platform and not the product. And by that the traffic might increase but the conversion rate is lower than it was before. Moreover, by visualizing a certain interior style in a rendered image, businesses lose on potential buyers with different taste in home decoration.
                                Ultimately, the goal should be to convert traffic to closed deals, not directing them to a site full of pictures of soulless spaces and unrelatable designs.
                                <br /><br />
                                We got the formula! At Intertwine Studios we focus on influencing the clients’ emotions and capturing their imagination, making them visualize a customized and relatable experience through a story driven animation video and a series of well crafted rendered images.
                                <br /><br />
                            </b>
                            </p>
                        </div>
                    </div>
                    <div className="mission-section">
                        <div className="mission-title">
                            <h1><b>ARE YOU AN INTERIOR DESIGNER?</b></h1>
                            <h5>We can help <b>you</b> present your projects in a way that helps create <b>long-lasting impression</b> for you and your clients.</h5>
                        </div>
                        <div>
                            <p>
                                <b>Interior designers can produce a wonderful piece of art but fail to market it or project the emotions and vibe that the designer wants to send. The focus on photorealism alone and trying to illustrate a vision through colors, lighting and decoration often produces an unrealistic scenery that is underwhelming.
                                    <br /><br />
                                    Potential clients might lack the attention to details or the appreciation to design concepts and artwork, by that their decisions turn black and white and the designer might feel underappreciated. No increase in traffic conversion is noticed hence a bad marketing strategy.
                                    <br /><br />
                                    Don’t worry! We got the solution. Our team focuses on creating a setting that fits your vision and tells a story through a well orchestrated scenery that will take your potential clients on an imaginary trip that resonates with their emotions.
                                    We can help you leave a long lasting impression on your clients and drive their decision to commit to your project.
                                </b>
                            </p>
                        </div>
                    </div>
                    <div className="mission-section">
                        <div className="mission-title">
                            <h1><b>DO YOU OWN A FURNITURE BRAND?</b></h1>
                            <h5>if <b>you're</b> looking for a <b>3D visualization</b> company to help <b>you</b> with <b>your furniture</b> products <b>marketing campaign</b>, then you have landed in the <b>right place!</b></h5>
                        </div>
                        <div>
                            <p><b>
                                Furniture brands usually post their new products across their pages and websites but get little to no interactions with their content. The days of filming a product and displaying it are past us, and businesses using this strategy cannot compete with the new marketing trends on social media.
                                <br /><br />
                                The reason this method fails is due to the lack of interest from the viewers. Today social media platforms are filled with generic content and ads, and old school content is deemed unoriginal and repetitive. Potential clients are not intrigued to think about the product in the context of their houses and not impressed enough to invest some time exploring what the business has to offer.
                                <br /><br />At Intertwine Studios we focus on capturing the imagination of your potential clients by providing context filled content with a marketing focus that is going to help you stand out amongst your competitors.
                            </b></p>
                        </div>
                    </div>
                    <div className="mission-section">
                        <div className="mission-title">
                            <h1><b>ARE YOU A PRODUCT DESIGNER?</b></h1>
                            <h5>There's nothing more convincing than a <b>well-told viusal story.</b><br />Get <b>your</b> design approved and <b>build your portfolio</b> with us!</h5>
                        </div>
                        <div>
                            <p><b>
                                Do you aim to increase traffic and sales for your designed products? You came to the right place!
                                Products are currently marketed with short form video content showcasing the actual item in action. With the rise of tiktok and Instagram reels, such videos facilitated a better way for businesses to show the value their products produce and attract potential clients by grabbing their attention.
                                <br /><br />
                                However with the increase of the sheer amount of videos posted daily and the tougher competition, going viral with your content has become much harder than ever, and videos without context and a backstory are not performing very well,the views are slowing down and following the “Algorithm” isn’t rewarding anymore.
                                <br /><br />
                                If you’ve made it this far then it seems like you have this issue. But don’t worry because we’ve got the solutions.
                                <br /><br />
                                At Intertwine Studios we take video content to the next level. Leveraging the power of computer generated imagery to create creative scenarios and produce eye capturing actions that will make your potential clients stop and admire the value that is being projected.

                            </b></p>
                        </div>
                    </div>

                </div>
            </div>
            {/* <div className="body">
                <div class="wrapper">
                    <div class="card">
                        <h1>
                            Cut out
                        </h1>
                    </div>
                </div>
            </div> */}
            <Footer />
        </div>
    );
}

export default Mission;