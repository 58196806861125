export const hitches = {
    "title": "Hitches",
    "description": "Visualize your space in a high quality and modernistic format that thrives for distinguishability. Connect your creativity to reality through us.\nThis premium service provides you with a rapid and detailed experience that is beneficial for short notice circumstances.",
    options: {
        "3d_Modeling": [
            "Basic Modeling - Exterior",
            "Basic Modeling - Interior",
            "Basic Environment and Context Modeling",
            "Modeling Software"
        ],
        "Rendering": [
            "Visual Effects",
            "Basic Context and Surrounding",
        ],
        "Extras": [
            "Photoshop Post-Production",
            "Lightroom Post-Production",
        ]
    }
}