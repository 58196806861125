export const knots = {
    "title": "Knots",
    "description": "Present your project in a unique, innovative, and contemporary way that will set you apart.\n This standard service provides you with a quick and cost-effective outcome that satisfies your requirements.\nIf you're a student with limited financial abilities and a strict deadline, this is the package for you.",
    options: {
        "3d_Modeling": [
            "Basic Modeling - Exterior",
            "Texturing - Exterior",
            "Modeling Software"
        ],
        "Rendering": [
            "Basic Visual Effects",
            "Basic Context and Surrounding",
        ],
        "Extras": [
            "Photoshop Post-Production",
            "Lightroom Post-Production",
        ]
    }
}