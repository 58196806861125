import React from 'react';
import { Carousel } from 'react-bootstrap';

import "./CarouselContainer.css";


const CarouselContainer = (props) => {
    return (
        <div class="car-wrapper">
            <div class="slogan">
                <p className="slogan-text">VISUALIZE . CAPTIVATE . AMPLIFY</p>
            </div>
            <Carousel id="home" controls={false} indicators={false} pause="false" className="carousel-responsivness">
                {
                    props.images.map((image, i) => (
                        <Carousel.Item interval={4000}>
                            <img
                                className="d-block w-100 carousel-image-height"
                                src={image["path"]}
                                alt={image["alt"]}
                            />
                            <Carousel.Caption>
                                <h3>{image["label"]}</h3>
                                <p>{image["desc"]}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))
                }
            </Carousel>
        </div>
    )
}

export default CarouselContainer;
