import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../../utils/firebase';

import logo from "../../../images/whitelogo.png";


import "./PortfolioPage.css";


const getImages = async () => {
    let images = [];
    const querySnapshot = await getDocs(collection(db, "portfolio"));
    querySnapshot.forEach((doc) => {
        console.log(`${doc.data()['path']}`);
        images.push(doc.data());
    });
    return images;
}


const PortfolioPage = () => {
    const [images, setImages] = useState([]);
    useEffect(() => {
        getImages().then(snapshot => {
            setImages(snapshot);
        });
    }, [setImages]);
    return (
        <div className="portfolio-body">
            <div className="portfolio-header">
                <div className="portfolio-logo">
                    <Link to="/" target="_blank" className="link-portfolio">
                        <div className="banner-container">
                            <img
                                alt=""
                                src={logo}
                                className="d-inline-block align-top website-logo"
                            />
                            <div className="title-container">
                                <p className="title">Intertwine</p>
                                <p className="subtitle">Studios</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="vl"></div>
                <h1 className="portfolio-title">PORTFOLIO PAGE</h1>
            </div>
            <div className="portfolio-grid">
                {
                    images.map((doc) => (
                        <div className="portfolio-img"  >
                            <img src={doc['path']} alt={doc['alt']} className="img-portfolio"/>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
export default PortfolioPage;